import { mapActions, mapGetters, mapMutations } from 'vuex'
import mixins from '@/mixins'
import moment from 'moment'
import CountryFlag from 'vue-country-flag'
import { debounce } from 'lodash'
import { userAdminsListTableHead } from '../../helpers/index'

export default {
  name: 'user-students-list',
  mixin: [mixins],
  data () {
    return {
      page: 1,
      pageCount: 20,
      itemsPerPage: 20,
      showRemoveDialog: false,
      removingItem: null,
      filter: {
        keywords: '',
        batch: '',
        country: '',
        course: '',
        isActive: true,
        courseUserId: ''
      },
      courseKeyword: '',
      searchCourseElem: '',
      searchInputCourse: ''
    }
  },
  components: {
    CountryFlag
  },
  computed: {
    ...mapGetters({
      isSimpleCourseLoading: 'courses/isSimpleCourseLoading',
      simpleCourses: 'courses/simpleCourses',
      courseUsersIds: 'courses/courseUsersIds',
      isCourseUsersIdsLoading: 'courses/isCourseUsersIdsLoading',

      list: 'userAdmins/list',
      loading: 'userAdmins/isListLoading',
      lengthList: 'userAdmins/listLength',
      skip: 'userAdmins/skip',
      limit: 'userAdmins/limit',
      isLoading: 'userAdmins/isLoading',
      filterStore: 'userAdmins/filter',
      accessCountries: 'profile/accessCountries'
    }),
    userAdminsListTableHead
  },
  created () {
    this.setDefaultFilter()
    this.fetchList()
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'userAdmins/FETCH_LIST',
      removeListItem: 'userAdmins/DELETE_USER',

      fetchCourses: 'courses/GET_COURSES_SIMPLE_RESPONSE',
      fetchCoursesUsersIds: 'courses/GET_COURSE_USERS_IDS'
    }),
    ...mapMutations({
      changeFilterStore: 'userAdmins/CHANGE_FILTER',
      changeSkip: 'userAdmins/CHANGE_SKIP'
    }),

    changeInputAutocomplete: debounce(function (val) {
      if (!val) return
      if (this.courseKeyword !== val) {
        this.courseKeyword = val
        this.fetchCourses({
          keywords: val
        })
      }
    }, 500),
    autoCompleteChangedCourse (e) {
      this.searchCourseElem = e
      this.fetchCoursesUsersIds({ id: e.id }).then(() => {
        this.filter.courseUserId = this.courseUsersIds
        this.changeFilter()
      })
    },
    setDefaultFilter () {
      Object.assign(this.filter, this.filterStore)
    },
    changeFilter (type = '') {
      if (type === 'course') {
        this.courseKeyword = ''
        this.searchCourseElem = ''
        this.filter.courseUserId = ''
      }
      this.changeFilterStore(this.filter)
      this.sendRequest()
    },
    removeUser () {
      this.removeListItem(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('successUserDelete'))
      })
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 1000)
  }
}
